export const ContactUsPageData = {
    CONTACTUS: {
        contactusheading: {
            HINDI: "आपकी सहायता करने में हमें आनंद होगा",
            ENGLISH: "We are always Happy to Help",
            MARATHI: "आम्हाला तुमची मदत करण्यात आनंद होईल"
        },
        contactusheading2: {
            HINDI: "हमेँ  अपनी समस्या के बारें में बताएं ,हम आपसे शीघ्र संपर्क करेंगे",
            ENGLISH: "Help us know the Issue and we would get back to you as soon as possible",
            MARATHI: "तुमच्या समस्येबद्दल आम्हाला सांगा, आम्ही तुमच्याशी त्वरित संपर्क करू"
        },
        teamcsp: {
            HINDI: "टीम सीएसपी आपकी समस्याओं को हल करने के लिए उत्सुक है",
            ENGLISH: "Team CSP is eager to solve your issues!",
            MARATHI: "टीम CSP तुमच्या समस्या सोडवण्यासाठी उत्सुक आहे"
        },
        filldetails: {
            HINDI: "यदि समस्या तकनीकी या सेवा से संबंधित है तो कृपया विवरण भरें और संक्षेप में समस्या की प्रकृति बताएं।",
            ENGLISH: "Please fill in the details alongside and state the nature of the issue in brief, if Technical or Service related.",
            MARATHI: "जर समस्या तांत्रिक किंवा सेवेशी संबंधित असेल तर कृपया तपशील भरा आणि समस्येचे स्वरूप थोडक्यात स्पष्ट करा."
        },
        callus:{
            HINDI: "हमें कॉल करें",
            ENGLISH: "Call us",
            MARATHI: "आम्हाला"
        },
        contactnumber:{
            HINDI: "+91 8421580866",
            ENGLISH: "+91 8421580866 ",
            MARATHI: "+91 8421580866 "
        },
        contactnumbertext: {
            HINDI: "पर , हम सोमवार से शनिवार तक सुबह 10.30 बजे से शाम 5.30 बजे तक उपलब्ध हैं",
            ENGLISH: "We are available on all week days from 10.30 am to 5.30 Pm",
            MARATHI: "वर कॉल करा, आम्ही सोमवार ते शनिवार सकाळी 10.30 ते संध्याकाळी 5.30 पर्यंत उपलब्ध आहोत"
        },
        mailus: {
            HINDI: "आप हमें",
            ENGLISH: "You can mail us at",
            MARATHI: "तुम्ही आम्हाला "
        },
        mail: {
            HINDI: "support@csp.in",
            ENGLISH: "support@csp.in",
            MARATHI: "support@csp.in"
        },
        address: {
            // HINDI: " पर मेल कर सकते हैं या हमारे कार्यालय में व्यक्तिगत रूप से मिल सकते हैं: 207, सिटी एवेन्यू वाकड, पिंपरी-चिंचवाड़, महाराष्ट्र 411057",
            // ENGLISH: "or see us in person in our office at 207, City Avenue Wakad, Pimpri-Chinchwad, Maharashtra 411057",
            // MARATHI: "वर मेल करू शकता किंवा आमच्या कार्यालयात प्रत्यक्ष भेटू शकता: 207, सिटी अव्हेन्यू वाकड, पिंपरी-चिंचवड, महाराष्ट्र 411057"
            HINDI: " पर मेल कर सकते हैं या हमारे कार्यालय में व्यक्तिगत रूप से मिल सकते हैं: जे.डी.सी.सी. बैंक कॉलोनी, पचोरा, पचोरा, जलगांव, महाराष्ट्र, 424201",
            ENGLISH: "or see us in person in our office at J.D.C.C. BANK COLONY, PACHORA, Pachora, Jalgaon, Maharashtra, 424201",
            MARATHI: "वर मेल करू शकता किंवा आमच्या कार्यालयात प्रत्यक्ष भेटू शकता: जे.डी.सी.सी. बँक कॉलनी, पाचोरा, पाचोरा, जळगाव, महाराष्ट्र, ४२४२०१"
        },
    }
}